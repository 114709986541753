import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { LogService } from '../../core/log/log.service';
import { onlyWhenOnline, onlyWhenAuthenticated } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { loadTopic, loadTopicSuccess, loadTopicFailure } from './topic.actions';
import { Topic } from './topic.model';
import { TopicService } from './topic.service';

@Injectable({ providedIn: 'root' })
export class TopicEffects {
	loadTopic$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTopic),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadTopic', action)),
			switchMap(() =>
				this.topicService.loadTopic().pipe(
					tap((data: Topic[]) =>
						this.logService.infoDebug('Effect: loadTopic data received --> ', data)
					),
					map((datiTopic: Topic[]) => loadTopicSuccess({ datiTopic })),
					catchError((error: ErrorMessage) => of(loadTopicFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private logService: LogService,
		private store: Store,
		private topicService: TopicService
	) {}
}
