import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthLoginState, LoginType } from '../auth/auth.models';
import { CallService } from '../call-service/browser/call.service';
import { getCreateSessionUrl } from '../router/routes-provider';
import { SessionDTO } from './session.models';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	constructor(private callService: CallService) {}

	CreateSession(
		params: ParamMap,
		authLoginState: AuthLoginState,
		oldSessionID: number,
		requestUrl: string,
		referrer: string
	): Observable<{ sessionID: number }> {
		const sessionDTO: SessionDTO = <SessionDTO>{};

		// http://localhost:4200/home?%20tag=33333&cnt=22222&utm_source=pippo&utm_campaign=pluto&utm_medium=paperino&utm_content=minni
		const url = getCreateSessionUrl();

		sessionDTO.requestURL = requestUrl;

		if (params.has('tag')) {
			if (!isNaN(parseInt(params.get('tag'), 10))) {
				sessionDTO.tagGlobale = parseInt(params.get('tag'), 10);
			}
		}
		if (params.has('cnt')) {
			if (!isNaN(parseInt(params.get('cnt'), 10))) {
				sessionDTO.tagContatore = parseInt(params.get('cnt'), 10);
			}
		}
		if (params.has('utm_source')) {
			sessionDTO.utmSource = params.get('utm_source');
		}
		if (params.has('utm_medium')) {
			sessionDTO.utmMedium = params.get('utm_medium');
		}
		if (params.has('utm_campaign')) {
			sessionDTO.utmCampaign = params.get('utm_campaign');
		}
		if (params.has('utm_content')) {
			sessionDTO.utmContent = params.get('utm_content');
		}
		// referrer: string;
		switch (authLoginState) {
			case AuthLoginState.LOGGEDGUEST:
				sessionDTO.tipoLogin = LoginType.GUESTLOGIN;
				break;
			case AuthLoginState.LOGGEDSOFT:
				sessionDTO.tipoLogin = LoginType.SOFTLOGIN;
				break;
			case AuthLoginState.LOGGEDHARD:
				sessionDTO.tipoLogin = LoginType.HARDLOGIN;
				break;
		}

		sessionDTO.referrer = referrer;
		sessionDTO.IDSessione = oldSessionID;

		const result = this.callService.CallApi<{ sessionID: number }>(
			'POST',
			url,
			JSON.stringify(sessionDTO)
		);
		return result;
	}
}
