/* eslint-disable ngrx/avoid-mapping-selectors */
import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { openDialog } from '../../shared/dialog/dn-dialog.actions';
import { SigninDialogComponent } from '../../shared/dialog/signin-dialog/signin-dialog.component';
import { AuthLoginState } from '../auth/auth.models';
import { selectAuthLoginState } from '../auth/auth.selectors';
import { showSnackbar } from '../notifications/notification.actions';
import { NotificationService } from '../notifications/notification.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatedOnlyGuardService {
	constructor(
		private store: Store,
		private router: Router,
		private notificationService: NotificationService,
		private translate: TranslateService
	) { }

	canLoad(
		route: Route,
		segments: UrlSegment[]
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.store.select(selectAuthLoginState).pipe(
			filter(
				(authLoginState) =>
					authLoginState !== AuthLoginState.NOTLOGGED &&
					authLoginState !== AuthLoginState.REFRESHING
			),
			map((authLoginState) => {
				if (authLoginState === AuthLoginState.LOGGEDHARD) return true;
				else {
					this.store.dispatch(
						openDialog({
							content: undefined,
							urlToDownload: undefined,
							urlWithContext: undefined,
							disableClose: true,
							componentType: SigninDialogComponent,
							panelClass: 'dn-dialog-small',
							data: {
								dialogTitle: this.translate.instant('dottnet.profile.reserved')
							}
						})
					);

					// this.store.dispatch(
					// 	showSnackbar({
					// 		payload: this.notificationService.getInfoNotification(
					// 			401,
					// 			'Dear User, sign in before accessing your profile area'
					// 		)
					// 	})
					// );
					// return this.router.parseUrl('home');
				}
			})
		);
	}
}
