import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';

export const saveError = createAction(
	'[Errors] SaveError',
	props<{ error: Error }>()
);
export const saveErrorSuccess = createAction(
	'[Errors] SaveErrorSuccess',
	props<{ sessionGuid: string }>()
);

export const saveErrorFailure = createAction(
	'[Errors] SaveErrorFailure',
	props<{ error: ErrorMessage }>()
);

