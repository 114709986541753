export interface Click {
	fromPage: string;
	toPage: string;
	tagName: string;
	text: string;
	action: string;
}

export interface ClickDetail {
	sessionID: number;
	page: string;
	contextTypeID: number;
	contextID: number;
	contentTypeID: number;
	contentID: number;
	templateID: number;
	permaLink: string;
	queryString: string;
}

export enum ClickOperation {
	Login = 1,
	RecuperaPassword = 2,
	Registrazione = 3,
	ModificaProfilo = 4,
	DisicrizioneNewsletter = 5,
	ModificaPassword = 6,
	AttivaAccount = 7,
	CodiceFiscaleEsistente = 8,
	IndirizzoEmailEsistente = 9,
	CompletaProfilo = 10,
	ChiusuraFormCompletaProfilo = 11,
	AggancioAnagraficaTokenPresente	= 12, // no
	AperturaCompletaProfilo = 13,
	Logout = 14,
	AggiungiTokenConIdSito = 15, // no
	AperturaFormCheckPrivacy = 16,
	ModificaFlagPrivacy = 17,
	PrintPdf = 18,
	SavePdf = 19,
}


export interface ClickLog {
	sessionID: number;
	clickLogOperationID: ClickOperation;
	method: string;
	content: string;
	outcome: boolean;
	message: string;
}

export interface ClickDetailID {
	clickDetailID: number;
}

export interface ClickLogID {
	clickLogID: number;
}


export interface TemplateAttributes {
	contextTypeID: number;
	contextID: number;
	templateID: number;
	contentTypeID: number;
}
