import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import { Topic } from './topic.model';
import { getTopicUrl } from '../../core/router/routes-provider';

@Injectable({ providedIn: 'root' })
export class TopicService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadTopic(): Observable<Topic[]> {
		const urltoCall = getTopicUrl();
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Topic[]>('GET', urltoCall);
		return result;
	}
}
