import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LogService } from '../log/log.service';
import { setCookieConsent, setCookieConsentSuccess } from './cookie-consent.actions';
import { map, tap } from 'rxjs/operators';
import { FBCookieAction, GACookieAction } from './cookie-consent.model';
import { isPlatformBrowser } from '@angular/common';
declare let fbq: Function; // fbq function declaration
@Injectable({ providedIn: 'root' })
export class CookieConsentEffects {
	setCookieConsent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCookieConsent),
			tap((cookies) => {
				if (isPlatformBrowser(this.platformId)) {
					this.logService.debug('Effect: setCookieConsent: ', cookies);

					const actionF: GACookieAction = cookies.cookieConsent.functional_google_analytics
						? GACookieAction.Granted
						: GACookieAction.Denied;
					gtag('consent', 'update', {
						analytics_storage: actionF
					});
					this.logService.info('Effect-setCookieConsent: Set Ga functional cookies to ', actionF);

					const action: GACookieAction = cookies.cookieConsent.marketing_google_analytics
						? GACookieAction.Granted
						: GACookieAction.Denied;
					gtag('consent', 'update', {
						ad_storage: action
					});
					this.logService.info('Effect-setCookieConsent: Set Ga marketing cookies to ', action);

					const consentFb = cookies.cookieConsent.marketing_meta
						? FBCookieAction.Granted
						: FBCookieAction.Denied;
					this.logService.info('Effect: setCookieConsent FB Component fb_consent: ', consentFb);
					fbq('consent', consentFb);
				}
			}),
			map(() => setCookieConsentSuccess())
		)
	);

	constructor(
		private actions$: Actions,
		private logService: LogService,
		@Inject(PLATFORM_ID) private platformId: Object

	) { }
}
