import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { showSnackbar, showSnackbarFromComponent } from './notification.actions';
import { NotificationDN } from './notification.model';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class NotificationEffects {
	showSnackbar$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(showSnackbar),
				map((action) => action.payload),
				tap((notification: NotificationDN) =>
					this.notificationService.showNotificationSnackbar(notification)
				)
			),
		{ dispatch: false }
	);

	showSnackbarFromComponent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(showSnackbarFromComponent),
				map((action) => action),
				tap((action) =>
					this.notificationService.showNotificationSnackbarFromComponent(
						action.component,
						action.payload,
						action.verticalPosition
					)
				)
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
