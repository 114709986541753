import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import { getProfessionsUrl } from '../../core/router/routes-provider';
import { ProfessionType } from './profession.model';

@Injectable({ providedIn: 'root' })
export class ProfessionService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadProfession(): Observable<ProfessionType[]> {
		const urltoCall = getProfessionsUrl();
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<ProfessionType[]>('GET', urltoCall);
		return result;
	}
}
