<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
    <img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
    <div class="dialog-title">{{ getPermissionTitle() }}</div>
    <button *ngIf="showClose" mat-icon-button (click)="goToContext()">
		<fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
	</button>
    <button *ngIf="!showClose" mat-icon-button>
	</button>
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
    <button *ngIf="showClose" mat-icon-button (click)="goToContext()">
		<fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
	</button>
    <button *ngIf="!showClose" mat-icon-button>
	</button>
    <div class="dialog-title">{{ getPermissionTitle() }}</div>
    <img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
</div>

<hr class="dialog-title-separator" />

<div class="flex-col">
    <section class="login-form">
        <mat-dialog-content *ngIf="
				[
					PermissionVisualizationType.Signin,
					PermissionVisualizationType.UserSessionExpired
				].includes(permissionType)
			">
            <dottnet-form-signin (formEventEmitter)="onPost($event)" [permissionType]="permissionType" (closeDialogEmitter)="closeDialog()"></dottnet-form-signin>
        </mat-dialog-content>

        <mat-dialog-content *ngIf="permissionType === PermissionVisualizationType.UserNotActivated">
            <dottnet-mail-confirm (sendMailEvent)="sendMail()" [user$]="user$"></dottnet-mail-confirm>
        </mat-dialog-content>

        <mat-dialog-content *ngIf="
				permissionType !== PermissionVisualizationType.Signin &&
				permissionType !== PermissionVisualizationType.UserSessionExpired &&
				permissionType !== PermissionVisualizationType.UserNotActivated
			">
            <p>
                {{ getPermissionBody() }}
            </p>
        </mat-dialog-content>
    </section>
</div>