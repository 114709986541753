<div class="flex-col flex-wrap flex-justify-center flex-align-center footer">
	<div class="flex-row flex-wrap flex-justify-space-around flex-align-center footer-container">
		<div class="flex-col flex-justify-center flex-align-center footer-info">
			<img src="/assets/logo.png" width="100" />

			<div class="flex-row flex-wrap info-links">
				<a href="/chi-siamo">{{ 'dottnet.footer.chiSiamo' | translate }}</a>
				<a href="/redazione">{{ 'dottnet.footer.redazione' | translate }}</a>
				<a href="mailto:redazione@merqurio.it">{{ 'dottnet.footer.contatti' | translate }}</a>
			</div>
		</div>

		<div class="flex-col flex-wrap flex-justify-center flex-basis-auto footer-social">
			<div class="flex-col flex-justify-center flex-align-center footer-social-wrapper">
				<span>{{ 'dottnet.footer.seguici' | translate }}</span>
				<div id="footer-social-icons" class="flex-row">
					<a href="https://www.facebook.com/dottnet.it/" target="_blank"
						><img src="/assets/facebook-mobile.png" width="40"
					/></a>
					<a href="https://twitter.com/DottNet" target="_blank"
						><img src="/assets/twitter-mobile.png" width="40"
					/></a>
					<!-- <a href="https://plus.google.com/113510736576601766683/posts" target="_blank"
						><img src="/assets/google-mobile.png" width="20"
					/></a> -->
				</div>
				<p>{{ 'dottnet.footer.numeroVerde' | translate }}</p>
			</div>
		</div>

		<!-- <div class="flex-row flex-justify-space-evenly flex-align-center flex-basis-auto footer-logo">
            <a href="https://www.healthonnet.org/HONcode/Italian/?HONConduct777672" onclick="window.open(this.href); return false;">
                <img src="https://www.honcode.ch/HONcode/Seal/HONConduct777672_hr2.gif" title="Aderiamo allo standard HONcode per l'affidabilità dell'informazione medica" alt="Aderiamo allo standard HONcode per l'affidabilità dell'informazione medica" /></a>
            <p>
                {{ 'dottnet.footer.honCode' | translate }}
                <a href="http://www.healthonnet.org/HONcode/Italian/" onclick="window.open(this.href); return false;">
					{{ 'dottnet.footer.honCode2' | translate }}</a
				>
            <a
              href="https://www.healthonnet.org/HONcode/Italian/?HONConduct777672"
              onclick="window.open(this.href); return false;"
              >{{ 'dottnet.footer.check' | translate }}
            </a>
                </p>
        </div> -->
	</div>
	<div class="flex-fill flex-row flex-wrap flex-justify-center flex-align-center footer-bottom">
		<p class="flex-basis-auto">
			<a href="condizioni-utilizzo">{{ 'dottnet.footer.condizioni' | translate }}</a> |
			<a href="privacy">{{ 'dottnet.footer.privacy' | translate }}</a> |
			<a *ngIf="isUserLogged" href="settings/DisiscrizioneDottnet"
				>{{ 'dottnet.footer.cancellazione' | translate }} |</a
			>
			<span> {{ 'dottnet.footer.company' | translate }} </span>
		</p>
	</div>
</div>
