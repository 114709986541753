import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../call-service/browser/call.service';
import { getCreateClickLogUrl, getCreatePageViewUrl } from '../router/routes-provider';
import { ClickDetail, ClickDetailID, ClickLog, ClickLogID } from './click.models';

@Injectable({
	providedIn: 'root'
})
export class ClickService {
	CreatePageView(clickDetail: ClickDetail): Observable<ClickDetailID> {
		const url = getCreatePageViewUrl();

		const result = this.callService.CallApi<ClickDetailID>(
			'POST',
			url,
			JSON.stringify(clickDetail)
		);
		return result;
	}

	CreateClickLog(clickLog: ClickLog): Observable<ClickLogID> {
		const url = getCreateClickLogUrl();

		const result = this.callService.CallApi<ClickLogID>(
			'POST',
			url,
			JSON.stringify(clickLog)
		);
		return result;
	}

	constructor(private callService: CallService) {}
}
