import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { saveError } from './error.actions';
import { Store } from '@ngrx/store';
import { LogService } from '../log/log.service';
import { saveLogs, saveLogsByDate } from '../../domain/log/log.actions';
import { LogLevel } from '../../domain/log/log.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class DottnetErrorHandler extends ErrorHandler {
	constructor(
		private injector: Injector,
	) {
		super();
	}

	handleError(error: Error) {

		console.log('Error handler activated', error);

		// here we inject services because the errorHandler is created before providers, and importing it the standard way failed with a circular dependency injection
		// https://stackoverflow.com/questions/41585863/injecting-services-in-custom-errorhandler-causes-cannot-instantiate-cyclic-depe

		const platformId = this.injector.get(PLATFORM_ID);
		
		const store = this.injector.get(Store);
		const logService = this.injector.get(LogService);

		logService.info('Unhandled error', error.message);
		const logsStartingDate: Date = new Date();

		// minutes to subtract in milliseconds
		const minutesToSubtract: number = 20 * 60000;
		// To unix time
		const startingDate = new Date(logsStartingDate.getTime() - minutesToSubtract);

		// if error is 'google is not defined' and ADS BLock is active, it means that adBlock blocks download of js files
		// and ads functions can't  work properly. We don't send logs to client
		if (
			error?.message.toLowerCase().includes('google is not defined') &&
			globalThis.adsVar === 'ADSBLOCK'
		) {
			logService.info('Google is not defined because of AD block active. Not sending logs to client');
		} else {
			if (isPlatformBrowser(platformId)) {

				store.dispatch(saveLogsByDate({ startingDate: startingDate }));

				store.dispatch(saveError({ error }));
			} else {
				logService.info('Error handler not saving logs serverside');		
			}
		}

		// if error is chunkLoadError, this means that we have published a new app version, and index.html has been loaded from cache, and tries to load js files that don't exist anymore
		// reloadForm is a global function defined in index.html that does the post of a form, which FORCSES browser to reload the page from the server
		if (error?.message.toLowerCase().includes('chunkloaderror')) {
			logService.info('Reloading page because of ChunckLoadError');
			//@ts-ignore
			reloadForm();
		}

		super.handleError(error);
	}
}
