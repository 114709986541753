import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import { getDnLivreaConfig } from '../../core/router/routes-provider';


@Injectable({ providedIn: 'root' })
export class InitService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadDnLivreaConfig(): Observable<boolean> {
		const urltoCall = getDnLivreaConfig();
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<boolean>('GET', urltoCall);
		return result;
	}
}
