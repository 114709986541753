import { dnLivreaConfig, dnLivreaConfigFailure, dnLivreaConfigSuccess } from './init.actions';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions,  createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { LogService } from '../log/log.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { InitService } from './init.service';

@Injectable({ providedIn: 'root' })

export class InitEffects {


  dnLivreaConfig$ = createEffect(() =>
      this.actions$.pipe(
        ofType(dnLivreaConfig),
        tap(() => this.logService.info('Effect: dnLivreaConfig')),
        switchMap(() =>
          this.initService.loadDnLivreaConfig().pipe(
            tap((data) =>
              this.logService.infoDebug('Effect: loadDnLivreaConfig data received --> ', data)
            ),
            map((livreaActive: boolean) => dnLivreaConfigSuccess({ livreaActive })),
            catchError((error: ErrorMessage) => of(dnLivreaConfigFailure({ error })))
          )
        )
      )
    );

	constructor(
		private actions$: Actions,
		private logService: LogService,
    private initService: InitService,
	) {}
}
